import { render, staticRenderFns } from "./PageCashCoupon.vue?vue&type=template&id=8e862caa&scoped=true"
import script from "./PageCashCoupon.vue?vue&type=script&lang=js"
export * from "./PageCashCoupon.vue?vue&type=script&lang=js"
import style0 from "./PageCashCoupon.vue?vue&type=style&index=0&id=8e862caa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e862caa",
  null
  
)

export default component.exports