<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="活动名称" prop="actName">
          <a-input v-model="params.actName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="活动状态" prop="status">
          <a-select
            v-model="params.status"
            placeholder="请选择"
            style="min-width: 120px"
          >
            <a-select-option
              v-for="item in [
              { value: 1, label: '有效' },
              { value: 2, label: '无效' },
            ]"
              :key="item.value"
              :value="item.value"
            >{{item.label}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="$router.push('/activity/cash_coupon/add')">新建代金券活动</a-button>
      <a-button type="link" @click="$router.push('/activity/cash_coupon/coupons')">维护代金券 →</a-button>
    </div>
    <base-table
      bordered
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="record => record.cashCouponActId"
      :loading="tableLoading"
      @change="handleTableChange"
    >
      <template slot="actName" slot-scope="text,record">
        <a-button
          type="link"
          @click="goDetail(record, '1')"
        >{{text}}</a-button>
      </template>

      <template #operation="record">
        <a-button
          type="link"
          @click="goDetail(record)"
        >编辑</a-button>
        <a-popconfirm
          placement="topRight"
          title="确定要删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteItem(record.cashCouponActId)"
        >
          <a-button
            :loading="deleteLoadingItem === record.cashCouponActId"
            type="link"
          >删除</a-button>
        </a-popconfirm>
      </template>
    </base-table>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, onUnmounted } from '@vue/composition-api'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import moment from 'moment'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: '活动名称',
    dataIndex: 'actName',
    scopedSlots: { customRender: 'actName' }
  },
  {
    title: '发行量',
    dataIndex: 'sendNum',
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    customRender: x => moment(x).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    customRender: x => moment(x).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '活动状态',
    dataIndex: 'status',
    customRender: x => ({ 1: '有效', 2: '无效' })[x]
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' }
  },
]
export default {
  name: 'PageCashCoupon',
  setup (props, { root }) {
    const state = reactive({
      params: {
        shopId: getSession(SHOPID),
        actName: '',
        status: undefined,
      },
      formRef: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      tableLoading: false
    })
    const callback = () => { getTableData() }
    onMounted(() => {
      getTableData()
      root.$bus.$on('PageCashCoupon:refresh', callback)
    })
    onUnmounted(() => {
      root.$bus.$off('PageCashCoupon:refresh', callback)
    })
    async function getTableData () {
      state.tableLoading = true
      let { code, data, page, msg } = await activity.getCashCouponActivityPage({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        shopId: getSession(SHOPID),
        ...state.params
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableData()
    }

    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }

    function goDetail (record, readonly) {
      root.$router.push(
        `/activity/cash_coupon/${readonly ? 'detail' : 'edit'}/${record.cashCouponActId}`
      )
    }

    const deleteLoadingItem = ref(null)
    async function deleteItem (id) {
      deleteLoadingItem.value = id
      const { code, msg } = await activity.deleteCashCouponActivity(id)
      deleteLoadingItem.value = null
      if (code === '00000') {
        message.success('删除成功')
        getTableData()
      } else {
        message.error('删除失败')
      }
    }

    return {
      moment,
      columns,
      ...toRefs(state),
      handleReset,
      handleSearch,
      handleTableChange,
      getTableData,
      goDetail,
      deleteItem,
      deleteLoadingItem,
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-foot {
  padding-top: 18px;
  margin-top: 18px;
  border-top: 1px solid #ddd;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
.role-name-box {
  display: flex;
  align-items: center;
  .official-flag {
    background: @main-color;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 6px;
    font-weight: bolder;
    flex-shrink: 0;
  }
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
